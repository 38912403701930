@tailwind base;
@tailwind components;
@tailwind utilities;

.word {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium",
    "游ゴシック", "Yu Gothic", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "メイリオ",
    Meiryo, sans-serif;
}

.text-adjust {
  font-size: 4vw;
  white-space: nowrap;
  word-wrap: keep-all;
}
